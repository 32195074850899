










































































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { CalendarTimestamp } from "vuetify";
import DayOffModule from "@/store/modules/day-off-module";
import { DayOffDailogInterface, DayOffInterface } from "@/types/day-off.type";
import { VuetifyCalendar, VuetifyCalendarEvent } from "@/types/vuetify.type";
import { has } from "lodash";
import moment from "moment";
import DayOffDetailDialog from "@/components/day-off/DayOffDetailDialog.vue";
import { DialogInterface } from "@/types/component.type";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import { RoleMixin } from "@/mixins/role-mixin";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";

type CalendarEvent = VuetifyCalendarEvent & DayOffInterface;

@Component({
  components: {
    DayOffDetailDialog,
    ConfirmationDialog,
  },
})
export default class DayOff extends Mixins(RoleMixin) {
  private focus = "";
  private type = "month";
  private typeToLabel = {
    month: "Month",
    week: "Week",
    day: "Day",
    "4day": "4 Days",
  };
  private selectedEvent: CalendarEvent = {} as CalendarEvent;
  private selectedElement: EventTarget | null = null;
  private selectedOpen = false;
  private typeToColor: Record<string, string> = {
    holiday: "primary",
    "work-off day": "secondary",
  };
  private eventToolbarMenu: boolean = false;
  private updateEventKey: number = 0;

  @Ref()
  private readonly calendar!: VuetifyCalendar;

  @Ref()
  private readonly dayOffDialog!: DayOffDailogInterface;

  @Ref()
  private readonly deleteDialog!: DialogInterface;

  private mounted() {
    this.calendar.checkChange();
  }

  private async created() {
    await DayOffModule.getDayOffs(moment().format("YYYY-MM"));
  }

  private get dayOffs(): DayOffInterface[] {
    return DayOffModule.dayOffs;
  }

  private viewDay({ date }: any) {
    this.focus = date;
    this.type = "day";
  }

  private setToday() {
    this.focus = "";
  }

  private showEvent({ nativeEvent, event }: { nativeEvent: MouseEvent; event: CalendarEvent }) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => (this.selectedOpen = true), 10);
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  private createDayOff({ date }: CalendarTimestamp) {
    if (!this.selectedOpen && this.isAdmin) {
      this.dayOffDialog.setDayOffDate(date);
      this.dayOffDialog.openDialog();
    }
  }

  private createNewDayOff() {
    const date = moment().format("YYYY-MM-DD");
    this.dayOffDialog.setDayOffDate(date);
    this.dayOffDialog.openDialog();
  }

  private get events(): CalendarEvent[] {
    return this.dayOffs.map((value) => {
      return { start: value.date, ...value };
    });
  }

  @Watch("events")
  private onEventsChange() {
    this.updateEventKey++;
  }

  private getEventColor(event: CalendarEvent): string {
    if (has(this.typeToColor, event.typeDisplay!)) {
      return this.typeToColor[event.typeDisplay!];
    }
    return "";
  }

  private async onCalendarTitleChange(newTitle: string) {
    const month = moment(newTitle, "MMMM YYYY").format("YYYY-MM");
    await DayOffModule.getDayOffs(month);
  }

  private async deleteDayOff() {
    try {
      await DayOffModule.deleteDayOff(this.selectedEvent.id!);
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Delete day off successfully!",
    });
  }

  private updateMonth(type: string) {
    if (type === "next") {
      this.calendar.next();
    } else {
      this.calendar.prev();
    }
    this.$nextTick(() => {
      this.onCalendarTitleChange(this.calendar.title);
    });
  }

  private updateDayOff() {
    this.dayOffDialog.setDayOff(this.selectedEvent);
    this.dayOffDialog.openDialog();
  }
}
