import { getModule, Module, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { capitalize } from "lodash";
import { DayOffInterface, DayOffTypeInterface } from "@/types/day-off.type";
import { createDayOff, getDayOffs, getDayOffTypes, deleteDayOff, updateDayOff } from "@/services/day-off.service";

interface DayOffModuleInterface {
  dayOffs: DayOffInterface[];
}

@Module({
  dynamic: true,
  name: "dayOff",
  store,
})
class DayOffModule extends VuexModule implements DayOffModuleInterface {
  public dayOffs: DayOffInterface[] = [];
  public dayOffTypes: DayOffTypeInterface[] = [];

  @MutationAction({ mutate: ["dayOffs"] })
  public async getDayOffs(month: string) {
    const dayOffs: DayOffInterface[] = await getDayOffs(month);
    return { dayOffs };
  }

  @MutationAction({ mutate: ["dayOffTypes"] })
  public async getDayOffTypes() {
    const dayOffTypes: DayOffTypeInterface[] = await getDayOffTypes();
    return { dayOffTypes };
  }

  @MutationAction({ mutate: ["dayOffs"] })
  public async addDayOff(data: DayOffInterface) {
    const newDayOffs: DayOffInterface = await createDayOff(data);
    const { dayOffs }: DayOffModuleInterface = { ...(this.state as DayOffModuleInterface) };
    dayOffs.push(newDayOffs);
    return { dayOffs };
  }

  @MutationAction({ mutate: ["dayOffs"] })
  public async deleteDayOff(id: number) {
    await deleteDayOff(id);
    const { dayOffs }: DayOffModuleInterface = { ...(this.state as DayOffModuleInterface) };
    const deletedIndex = dayOffs.findIndex((dayOff) => dayOff.id === id);
    if (deletedIndex !== -1) {
      dayOffs.splice(deletedIndex, 1);
    }
    return { dayOffs };
  }

  @MutationAction({ mutate: ["dayOffs"] })
  public async updateDayOff(data: DayOffInterface) {
    const updatedDayOff = await updateDayOff(data);
    const { dayOffs }: DayOffModuleInterface = { ...(this.state as DayOffModuleInterface) };
    const updatedIndex = dayOffs.findIndex((dayOff) => dayOff.id === updatedDayOff.id);
    if (updatedIndex !== -1) {
      dayOffs[updatedIndex] = updatedDayOff;
    }
    return { dayOffs: [...dayOffs] };
  }

  public get dayOffTypesCapitalized(): DayOffTypeInterface[] {
    return this.dayOffTypes.map((value) => {
      let { name } = value;
      name = name.split(" ").map(capitalize).join(" ");
      return { ...value, name };
    });
  }
}

export default getModule(DayOffModule);
