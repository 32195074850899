

























































import { Component, Ref, Vue } from "vue-property-decorator";
import { DayOffDailogInterface, DayOffInterface, DayOffTypeInterface } from "@/types/day-off.type";
import DayOffModule from "@/store/modules/day-off-module";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import { VuetifyForm } from "@/types/vuetify.type";

@Component
export default class DayOffDetailDialog extends Vue implements DayOffDailogInterface {
  private get dayOffTypes(): DayOffTypeInterface[] {
    return DayOffModule.dayOffTypesCapitalized;
  }

  @Ref()
  private readonly form!: VuetifyForm;

  private dialog: boolean = false;
  private menu: boolean = false;
  private defaultDayOff: DayOffInterface = {
    name: "",
    date: "",
    type: null,
  };
  private dayOff: DayOffInterface = { ...this.defaultDayOff };
  private nameRule = [(v: any) => !!v || "Name is required"];
  private dateRule = [(v: any) => !!v || "Date is required"];
  private typeRule = [(v: any) => !!v || "Type is required"];

  public openDialog() {
    if (this.form) {
      this.form.resetValidation();
    }
    this.dialog = true;
  }

  public closeDialog() {
    this.dialog = false;
    this.dayOff = { ...this.defaultDayOff };
  }

  public setDayOffDate(date: string) {
    this.dayOff = { ...this.defaultDayOff, date };
  }

  public setDayOff(dayOff: DayOffInterface) {
    this.dayOff = dayOff;
  }

  private async created() {
    await DayOffModule.getDayOffTypes();
  }

  private async saveDayOff() {
    if (!this.form.validate()) {
      return;
    }

    let submitMode = "";
    try {
      if (this.dayOff.id) {
        await DayOffModule.updateDayOff(this.dayOff);
        submitMode = "Update";
      } else {
        await DayOffModule.addDayOff(this.dayOff);
        submitMode = "Create";
      }
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    this.closeDialog();
    await SnackbarModule.setSnack({
      color: "success",
      message: `${submitMode} day off successfully!`,
    });
  }
}
