import axios from "@/plugins/axios-setting";
import { DayOffInterface, DayOffTypeInterface } from "@/types/day-off.type";

export function getDayOffs(month: string): Promise<DayOffInterface[]> {
  const searchParams = new URLSearchParams();
  if (month) {
    searchParams.set("month", month);
  }
  const url = `/api/day-off/?${searchParams.toString()}`;
  return axios.get(url);
}

export function getDayOffTypes(): Promise<DayOffTypeInterface[]> {
  return axios.get("/api/day-off-type/");
}

export function createDayOff(data: DayOffInterface): Promise<DayOffInterface> {
  return axios.post("/api/day-off/", data);
}

export function deleteDayOff(id: number): Promise<DayOffInterface> {
  return axios.delete(`/api/day-off/${id}/`);
}

export function updateDayOff(data: DayOffInterface): Promise<DayOffInterface> {
  const { id } = data;
  return axios.put(`/api/day-off/${id}/`, data);
}

export function getDaysOffInRange(startDate: Date, endDate: Date): Promise<number> {
  return axios.post("/api/day-off-utils/", {
    startDate: startDate.toISOString().substr(0, 10),
    endDate: endDate.toISOString().substr(0, 10),
  });
}
